import './App.css';
import { PaneView } from 'grevlingui';
import ClickTracker from "./ClickTracker/ClickTracker";
import ChaosChance from "./ChaosChance/ChaosChance";
import { useEffect } from "react";


const App = () => {
    let wakeLock = null;
    useEffect(() => {
        try {
            navigator.wakeLock.request("screen").then((wl) => {wakeLock = wl;});
            document.addEventListener("visibilitychange", async () => {
                if (wakeLock !== null && document.visibilityState === "visible") {
                    wakeLock = await navigator.wakeLock.request("screen");
                }
            });
        } catch (err) {
            // The Wake Lock request has failed - usually system related, such as battery.
            alert(`${err.name}, ${err.message}`);
        }
        return () => {
            wakeLock?.release().then(() => {
                wakeLock = null;
            });
        };
    }, []);

    return (
        <div className="App">
            <PaneView startOnPane={1}>
                <ClickTracker/>
                <ChaosChance/>
            </PaneView>
        </div>
    );
}

export default App;
