import React from 'react';

export const Incrementor = ({value, setValue, color}) => (
    <div className="incrementor">
        <div className="input">
            <button className="click-tracker-button" onClick={() => setValue(value + 1)}>+</button>
            <h1 className={`${color}Text`}>{value}</h1>
            <button className="click-tracker-button" onClick={() => setValue(value - 1)}>-</button>
        </div>
    </div>
);