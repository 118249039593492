import React from 'react';
import './FriendTracker.css';

export const FriendTracker = ({
                                  friends,
                                  setFriends
}) => {
    const renderFriend = (f, i) => f === null ? (
        <div
            key={`friend${i}`}
            className={'friendSpacer'}
        />
    ) :(
        <div
            key={`friend${i}`}
            className={`friend${f ? ' ready' : ''}`}
            onClick={() => setFriends(fr => fr.with(i, !fr[i]))}
        />
    )

    if (!friends || !friends.some(f => f !== null))
        return <></>;

    return (
        <div className="friendTrackerWrapper">
        <div className="friendTracker">
            {friends.map(renderFriend)}
        </div>
        </div>
    );
}