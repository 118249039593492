import './ClickTracker.css';
import {useEffect, useState} from "react";
import {Menu} from 'grevlingui';
import {Clicks} from "./Clicks";
import {Settings} from "./Settings/Settings";
import {Ouchies} from "./Ouchies";
import {FriendTracker} from "./FriendTracker/FriendTracker";
import {ReactComponent as HaptixIcon} from "../haptix.svg";
import {getStored, getStoredInt} from "../utils";


const getFriends = () => {
    const f = localStorage.getItem('friends');
    if (f)
        return JSON.parse(f);
    return Array(Number(9)).fill(false);
}

const getHaptix = () => {
    const v = localStorage.getItem('clicks-haptix');
    return v ? v === 'true' : false;
}

const getTrueArray = (size) => Array(Number(size)).fill(true)

function ClickTracker() {
    const [portrait, setPortrait] = useState(null);

    const [clicks, setClicks] = useState(getStored('clicks') ?? [true, true, true]);
    const [totalClicks, setTotalClicks] = useState(getStoredInt('totalClicks') ?? 3);

    const [friends, setFriends] = useState(getFriends());
    const [readyFriends, setReadyFriends] = useState(getStored('readyFriends') ?? []);
    const [life, setLife] = useState(getStored('life') ?? 7);
    const [sanity, setSanity] = useState(getStoredInt('sanity') ?? 7);
    const [damage, setDamage] = useState(getStored('damage') ?? getTrueArray(7));
    const [horror, setHorror] = useState(getStored('horror') ?? getTrueArray(7));

    const [fullScreen, setFullscreen] = useState(false);
    const [haptix, setHaptix] = useState(getHaptix());

    useEffect(() => {
        const query = matchMedia("all and (display-mode: fullscreen");
        query.onchange = () => {
            setFullscreen(query.matches)
        };
    }, [])


    const setScreenOrientation = () => {
        const p = window.matchMedia("(orientation: portrait)").matches;
        if (portrait !== p)
            setPortrait(p);
    }

    useEffect(setScreenOrientation);
    window.addEventListener("resize", setScreenOrientation);


    const reset = () => {
        haptix && navigator.vibrate( [50, 50, 100]);
        setClicks(getTrueArray(totalClicks));
        setReadyFriends(orf => orf.map(f => f === false ? true : f))
    }

    useEffect(() => {
        localStorage.setItem('clicks', JSON.stringify(clicks))
    }, [clicks])
    useEffect(() => {
        localStorage.setItem('damage', JSON.stringify(damage))
    }, [damage])
    useEffect(() => {
        localStorage.setItem('horror', JSON.stringify(horror))
    }, [horror])
    useEffect(() => {
        localStorage.setItem('readyFriends', JSON.stringify(readyFriends))
    }, [readyFriends])
    useEffect(() => {
        localStorage.setItem('totalClicks', totalClicks.toString())
        setClicks(prevClicks => prevClicks.length === totalClicks ? prevClicks : getTrueArray(totalClicks));
    }, [totalClicks])
    useEffect(() => {
        localStorage.setItem('life', life.toString())
        setDamage(prevDamage => prevDamage.length === life ? prevDamage : getTrueArray(life));
        localStorage.setItem('life', JSON.stringify(life))
    }, [life])
    useEffect(() => {
        localStorage.setItem('sanity', sanity.toString())
        setHorror(prevHorror => prevHorror.length === sanity ? prevHorror : getTrueArray(sanity));
    }, [sanity])
    useEffect(() => {
        localStorage.setItem('friends', JSON.stringify(friends))
        setReadyFriends(prevReadyFriends =>
            (friends.map(f => f ? true : null).some((f, i) =>
                (f === null && prevReadyFriends[i] !== null) ||
                (f !== null && prevReadyFriends[i] === null)
            )) ? friends.map(f => f ? true : null) : prevReadyFriends
        );
    }, [friends])

    const mainContent = (
        <>
            <FriendTracker
                friends={readyFriends}
                setFriends={setReadyFriends}
            />
            <div className="trackerContent">
                <Clicks
                    clicks={clicks}
                    setClicks={setClicks}
                    totalClicks={totalClicks}
                    reset={reset}
                    portrait={portrait}
                    setPortrait={setPortrait}
                    haptix={haptix}
                />
                <div
                    className="bottomTrackers"
                >
                    <Ouchies
                        life={life}
                        damage={damage}
                        setDamage={setDamage}
                        sanity={sanity}
                        horror={horror}
                        setHorror={setHorror}
                        portrait={portrait}
                        haptix={haptix}
                    />
                </div>
            </div>
        </>
    )

    return (
        <div className={`clickTrackerWrapper ${portrait ? 'portrait' : 'landscape'}`}>
            <Menu
                fader settings
            >
                <div className="settings">
                    <button
                        className={`haptixButton ${haptix ? 'active' : ''}`}
                        onClick={() => setHaptix(pv => {
                            localStorage.setItem('clicks-haptix', `${!pv}`);
                            return !pv
                        })}
                    >
                        <HaptixIcon/>
                    </button>
                    <button
                        className="fullScreenButton"
                        onClick={() => {
                            if (fullScreen)
                                document.exitFullscreen();
                            else
                                document.body.requestFullscreen();
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 4 40 40">
                            <path
                                d={
                                    fullScreen ?
                                        "M16.65 38v-6.65H10v-3h9.65V38Zm11.7 0v-9.65H38v3h-6.65V38ZM10 19.65v-3h6.65V10h3v9.65Zm18.35 0V10h3v6.65H38v3Z" :
                                        "M10 38v-9.65h3V35h6.65v3Zm0-18.35V10h9.65v3H13v6.65ZM28.35 38v-3H35v-6.65h3V38ZM35 19.65V13h-6.65v-3H38v9.65Z"
                                }
                            />
                            }
                        </svg>
                    </button>
                    <Settings
                        totalClicks={totalClicks}
                        setTotalClicks={setTotalClicks}
                        life={life}
                        setLife={setLife}
                        sanity={sanity}
                        setSanity={setSanity}
                        friends={friends}
                        setFriends={setFriends}
                    />
                </div>
            </Menu>
            {mainContent}
        </div>
    );
}

export default ClickTracker;
