import React, {useEffect, useState} from 'react';
import {Menu} from 'grevlingui'
import './ChaosChance.css';
import {Settings} from "./Settings/Settings";
import {Stats} from "./Stats/Stats";
import {getStored, getStoredInt} from "../utils";
import {sortSymbols} from "./utils";

export const values = {
    '2': 2,
    '1': 1,
    '0': 0,
    '-1': -1,
    '-2': -2,
    '-3': -3,
    '-4': -4,
    '-5': -5,
    'elder': 0,
    'fail': false
}

const getStoredTokens = () => {
    const stored = localStorage.getItem('chaos-tokens');

    let counts = stored ? JSON.parse(stored).sort(sortSymbols) : [
        { key: '2', count: 0 },
        { key: '1', count: 0 },
        { key: '0', count: 0 },
        { key: '-1', count: 0 },
        { key: '-2', count: 0 },
        { key: '-3', count: 0 },
        { key: '-4', count: 0 },
        { key: '-5', count: 0 },
        { key: 'elder', count: 0 },
        { key: 'fail', count: 0 },
        { key: 'skull', count: 0 },
        { key: 'cultist', count: 0 },
        { key: 'tablet', count: 0 },
        { key: 'fish', count: 0 },
    ];
    return counts.map(c => ({...c, value: values[c.key]}));
}

const ChaosChance = () => {
    const [tokens, setTokens] = useState(getStoredTokens());
    const [blessCurse, setBlessCurse] = useState(getStored('bless-curse'));
    const [minimumInterestingChance, setMinimumInterestingChance] = useState(getStoredInt('minimumInterestingChance') ?? 10);

    useEffect(() => {
        tokens && localStorage.setItem('chaos-tokens', JSON.stringify(tokens.map(t => {
            delete t.value;
            return t;
        })));
    }, [tokens])

    const saveBlessCurse = (t) => {
        localStorage.setItem('bless-curse', t);
        setBlessCurse(t)
    }
    const saveMinimumInterestingChance = (t) => {
        localStorage.setItem('minimumInterestingChance', t);
        setMinimumInterestingChance(t)
    }

    return (
        <div className="chaosChance">
            <Stats
                tokens={tokens.filter(t => t.count !== 0)}
                blessCurse={blessCurse}
                minimumInterestingChance={minimumInterestingChance}
            />
            <Menu settings fader>
                <Settings
                    tokens={tokens}
                    setTokens={setTokens}
                    blessCurse={blessCurse}
                    setBlessCurse={saveBlessCurse}
                    minimumInterestingChance={minimumInterestingChance}
                    setMinimumInterestingChance={saveMinimumInterestingChance}
                />
            </Menu>
        </div>
    );
}

export default ChaosChance;
