import React from 'react';

export const Ouchies = ({
                            life,
                            damage,
                            setDamage,
                            sanity,
                            horror,
                            setHorror,
                            portrait,
                            haptix
                        }) => {

    const toggle = (type, i) =>
        (type === 'damage' ? setDamage : setHorror)(pv => pv.with(i, !pv[i]));

    const topLife = Math.ceil(life / 2);
    const topSanity = Math.ceil(sanity / 2);

    const total = life + sanity;

    let spacers = total % 2 === 0 ? 3 : 2;

    const topSpacers = (life % 2 === 0 ? Math.ceil : Math.floor)(spacers / 2);

    const totaler = total + spacers;

    const width = Math.ceil(totaler);


    const spaceButtonRatioThing = 5

    let spacerUnits = width - 1;
    let clickUnits = width * spaceButtonRatioThing;

    let percentPerUnit = 120 / (spacerUnits + clickUnits);

    let units = ['vw', 'vh'];
    if (portrait)
        units.reverse();

    let size = `min(${percentPerUnit * spaceButtonRatioThing}${units[0]}, 40${units[1]})`;
    let bigSpace = `${percentPerUnit}${units[0]}`;
    let space = `${percentPerUnit / 3}${units[0]}`;


    const renderBoop = (intact, i, color, type) => (
        <div
            key={`${type}-${i}`}
            className={`boop ${color} ${intact ? 'intact' : ''}`}
            style={{
                margin: space
            }}
        >
            {color ? <svg
                viewBox="0 0 2 2"
                height={size}
                width={size}
            >
                <circle
                    r="1"
                    cx="1"
                    cy="1"
                    fill={intact ? color : '#222'}
                    onClick={() => {
                        haptix && navigator.vibrate(intact ? [10, 5, 10, 5, 10] : [10, 5, 10]);
                        toggle(type, i)
                    }}
                />
            </svg> : ''}
        </div>
    )
    const renderSpacer = (i, tag) =>
        <div
            className="ouchSpacer"
            style={{
                height: size,
                width: size
            }}
            key={`${tag}-${i}`}
        />

    let row1 = []

    //top lives
    for (let i = 0; i < topLife; i++) {
        row1.push(renderBoop(damage[i], i, 'red', 'damage'))
    }
    //top spacers
    for (let i = 0; i < topSpacers; i++) {
        row1.push(renderSpacer(i, 'beep'))
    }
    // top sanity
    for (let i = 0; i < topSanity; i++) {
        row1.push(renderBoop(horror[i], i, 'dodgerblue', 'horror'))
    }

    let row2 = []

    // bottom lives
    for (let i = topLife; i < life; i++) {
        row2.push(renderBoop(damage[i], i, 'red', 'damage'))
    }
    // bottom spacers
    for (let i = topSpacers; i < spacers; i++) {
        row2.push(renderSpacer(i, 'boop'))
    }
    // bottom sanity
    for (let i = topSanity - 1 + 1; i < sanity; i++) {
        row2.push(renderBoop(horror[i], i, 'dodgerblue', 'horror'))
    }

    let row2style = portrait ? {
        marginLeft: `-${bigSpace}`
    } : {
        marginTop: `-${bigSpace}`
    };

    return (
        <div
            className={`ouchies`}
        >
            <div className="row row1">
                {row1}
            </div>
            <div
                className="row row2"
                style={row2style}
            >
                {row2}
            </div>
        </div>
    );
}