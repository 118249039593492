
export const getStoredInt = key => {
    let foo = localStorage.getItem(key);
    if (!foo) return;
    return parseInt(foo);
}

export const getStored = (key) => {
    let foo = localStorage.getItem(key);
    if (!foo) return;
    return JSON.parse(foo);
}

export const getStoredBool = key =>
    localStorage.getItem(key);
