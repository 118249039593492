
const order = {
    elder:0,
    fail:1,
    skull:2,
    cultist:3,
    tablet:4,
    fish:5
}

export const sortSymbols = (a, b) => order[a.key] - order[b.key];