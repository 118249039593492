import React from 'react';
import {ResetButton} from 'grevlingui';

export const Clicks = ({clicks, setClicks, totalClicks, reset, haptix, portrait}) => {

    const spaceButtonRatioThing = 5

    let spacerUnits = totalClicks - 1;
    let clickUnits = totalClicks * spaceButtonRatioThing;

    let percentPerUnit = 70 / (spacerUnits + clickUnits);

    let units = ['vw', 'vh'];
    if (portrait)
        units.reverse();

    let size = `min(${percentPerUnit * spaceButtonRatioThing}${units[0]}, 40${units[1]})`;
    let space = `${percentPerUnit / 3}${units[0]}`;

    return portrait === null ? (
        <p>Disoriented</p>
    ) : (
        <div className="page clickTracker">
            <ResetButton
                className="click-tracker-button"
                onClick={reset}
            />
            <div
                className={'clicks'}
            >
                {clicks.map((ready, i) => (
                    <button
                        key={i}
                        className={`click-tracker-button click ${ready ? 'ready' : 'spent'}`}
                        onClick={() => {
                            haptix && navigator.vibrate(ready ? [10] : [100]);
                            setClicks(pv => pv.with(i, !ready));
                        }}
                        style={{
                            height: size,
                            width: size,
                            margin: space
                        }}
                    />
                ))}
            </div>
        </div>
    );
}