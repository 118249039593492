import React from 'react';

// const highlightColor = '#aa66aa';
// const color = '#771577';

const highlightColor = '#5c7959';
const color = '#10340d';

const strokeColor = '#000';

const tentacle = (
    <>
        <path
            d="M212.503 871.22C212.503 788.963 318.546 697.344 318.546 685.893L463.723 777.07C426.564 810.766 353.811 871.547 453.78 859.742C514.157 852.612 686.887 834.398 686.887 786.341C686.887 696.002 390.01 630.624 246.281 582.343C6.84172 501.911 -41.2096 312.383 47.9884 230.009C154.444 131.697 264.554 180.334 463.723 218.313C523.934 236.943 657.468 98.4843 753.5 125.631C890.552 164.374 911.662 277.887 876.737 347.662C853.935 393.216 750.036 431.601 728.371 431.601C717.742 431.601 571.454 415.34 530.45 318.515C609.505 397.483 711.087 396.064 721.008 395.374C866.489 385.263 867.329 192.851 721.008 192.851C658.78 192.851 586.137 305.992 434.499 295.418C316.811 287.212 123.755 218.313 114.389 318.515C105.023 418.716 229.053 465.675 367.492 503.491C512.792 543.182 928.918 629.397 815.056 865.453C767.769 963.486 518.81 984.592 473.139 989.604C286.626 1010.07 212.503 953.478 212.503 871.22Z"
            stroke={strokeColor}
            strokeWidth="20px"
        />
        <path
            d="M212.503 871.22C212.503 788.963 318.546 697.344 318.546 685.893L463.723 777.07C426.564 810.766 353.811 871.547 453.78 859.742C514.157 852.612 686.887 834.398 686.887 786.341C686.887 696.002 390.01 630.624 246.281 582.343C6.84172 501.911 -41.2096 312.383 47.9884 230.009C154.444 131.697 264.554 180.334 463.723 218.313C523.934 236.943 657.468 98.4843 753.5 125.631C890.552 164.374 911.662 277.887 876.737 347.662C853.935 393.216 750.036 431.601 728.371 431.601C717.742 431.601 571.454 415.34 530.45 318.515C609.505 397.483 711.087 396.064 721.008 395.374C866.489 385.263 867.329 192.851 721.008 192.851C658.78 192.851 586.137 305.992 434.499 295.418C316.811 287.212 123.755 218.313 114.389 318.515C105.023 418.716 229.053 465.675 367.492 503.491C512.792 543.182 928.918 629.397 815.056 865.453C767.769 963.486 518.81 984.592 473.139 989.604C286.626 1010.07 212.503 953.478 212.503 871.22Z"
            fill={color}
        />
        <path
            d="M280.367 847.945C280.367 791.121 352.365 695.788 352.365 687.878L438.463 729.502C412.794 752.78 218.541 876.868 443.75 881.728C536.014 883.719 764.136 813.899 740.075 756.856C691.571 641.862 384.504 545.73 280.367 532.506C51.42 503.434 -4.84537 295.802 71.7737 230.099C147.763 164.936 216.304 181.143 352.365 214.406C592.173 273.032 568.914 140.025 721.683 132.315C892.451 123.697 929.416 407.493 724.453 410.401C717.112 410.505 589.046 394.123 542.233 332.139C599.449 397.157 719.041 400.45 725.907 400.225C903.978 394.411 861.119 153.734 734.253 150.522C606.709 147.293 545.664 313.162 350.142 238.145C245.779 198.103 58.5291 215.434 74.678 315.915C104.248 499.904 249.841 492.531 339.967 517.243C440.316 544.758 835.233 630.1 779.152 802.246C754.748 877.157 548.076 929.557 430.819 924.261C301.332 918.413 280.367 904.77 280.367 847.945Z"
            fill={highlightColor}
        />
    </>
)

export const AutoFailWrapper = ({children}) => (
    <svg width="1000" height="1000" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="tentacleMask">
            <rect width="1000" height="1000" fill="white"/>
            <path d="M430.837 835.189L219.201 798.237L274.07 658.266L508.101 717.614L430.837 835.189Z" fill="black"/>
            <path d="M144.177 101.741L173.291 307.779L500.263 310.018L720.857 275.306L790.283 50.2324L144.177 101.741Z" fill="black"/>
        </mask>

        <g className="autoFailTentacle">
            {tentacle}
        </g>
        {children}
        <g mask="url(#tentacleMask)" className="autoFailTentacle">
            {tentacle}
        </g>
    </svg>
);