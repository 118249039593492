import React from 'react';
import {Incrementor} from "./Incrementor";
import {FriendTracker} from "../FriendTracker/FriendTracker";

export const Settings = ({
                             totalClicks,
                             setTotalClicks,
                             life,
                             setLife,
                             sanity,
                             setSanity,
                             friends,
                             setFriends
                         }) => (
    <div className="settings">
        <FriendTracker
            friends={friends}
            setFriends={setFriends}
        />
        <div className="incrementors">
            <Incrementor
                color={'blue'}
                value={sanity}
                setValue={setSanity}
            />
            <Incrementor
                color={'green'}
                value={totalClicks}
                setValue={setTotalClicks}
            />
            <Incrementor
                color={'red'}
                value={life}
                setValue={setLife}
            />
        </div>
    </div>
);