import React, {useEffect, useState, Fragment} from 'react';
import {NumberSelect} from "grevlingui";
import './Stats.css';
import {Icon} from "../Icons/Icon";
import {getStored, getStoredInt} from "../../utils";
import {getProbabilities} from "./utils";
import {AutoFailWrapper} from "../Icons/AutoFailWrapper";
import {values} from "../ChaosChance";

const getVariableTokenValues = () => {
    const storedValue = localStorage.getItem('chaos-variableTokenValues');
    return storedValue ? JSON.parse(storedValue) : {
        'fish': -1,
        'skull': -1,
        'tablet': -1,
        'cultist': -1,
    };
}

export const Stats = ({tokens, blessCurse, minimumInterestingChance}) => {
    const [variableTokenValues, setVariableTokenValues] = useState(getVariableTokenValues());
    const [hideVariableTokens, setHideVariableTokens] = useState(getStored('hide-variable-tokens') ?? false);
    const [closings, setClosings] = useState(Array(Number(6)).fill(new Date()))
    const [bless, setBless] = useState(getStoredInt('bless') ?? 0);
    const [curse, setCurse] = useState(getStoredInt('curse') ?? 0);

    const [autoFailSymbols, setAutoFailSymbols] = useState(getStored('autoFailSymbols') ?? [])
    const [surgeSymbols, setSurgeSymbols] = useState(getStored('surgeSymbols') ?? [])

    const saveAutoFailSymbols = (t) => {
        localStorage.setItem('autoFailSymbols', JSON.stringify(t));
        setAutoFailSymbols(t)
    }

    const saveSurgeSymbols = (t) => {
        localStorage.setItem('surgeSymbols', JSON.stringify(t));
        setSurgeSymbols(t)
    }

    const toggleSymbolSurge = (key) => {
        saveSurgeSymbols(
            surgeSymbols.includes(key) ?
                surgeSymbols.filter(k => k !== key):
                [...surgeSymbols, key]
            );
    }

    const toggleAutoFailSymbol = (key) => {
            saveAutoFailSymbols(
        autoFailSymbols.includes(key) ?
                autoFailSymbols.filter(k => k !== key):
                [...autoFailSymbols, key]
            );
    }

    useEffect(() => {
        localStorage.setItem('chaos-variableTokenValues', JSON.stringify(variableTokenValues));
    }, [variableTokenValues])

    useEffect(() => {
        localStorage.setItem('bless', bless.toString());
    }, [bless])

    useEffect(() => {
        localStorage.setItem('curse', curse.toString());
    }, [curse])

    useEffect(() => {
        localStorage.setItem('hide-variable-tokens', hideVariableTokens);
    }, [hideVariableTokens])

    const onOpenInput = (index) =>
        setClosings(c => c.map((prev, i) =>
            i === index ? prev : new Date()
        ))

    const renderVariableTokens = () =>
        variableTokens
            .map(t => t.key)
            .map(renderVariableTokenValueInput);

    if (tokens.length === 0)
        return <div className="page">
            <p>Chaos Tokens?</p>
        </div>

    const variableTokens = tokens.filter(t => variableTokenValues.hasOwnProperty(t.key));

    const renderVariableTokenValueInput = (key, i) => {
        const surge = surgeSymbols.includes(key);
        const autoFail = autoFailSymbols.includes(key);
        return (
            <div
                key={key}
                className={`variableToken${
                    autoFail ? ' autoFail' : ''
                }${
                    surge ? ' surge' : ''
                }`}
            >
                <span onClick={() => toggleAutoFailSymbol(key)}>
                    <AutoFailWrapper>
                        <Icon type={key}/>
                    </AutoFailWrapper>
                </span>
                <NumberSelect
                    size={3}
                    min={-9}
                    invert
                    max={0}
                    numberBgColor="#000000"
                    selectorColor="#222222"
                    value={variableTokenValues[key]}
                    setValue={v => {
                        setVariableTokenValues(vtv => ({...vtv, [key]: v}))
                    }}
                    timeout={3000}
                    closed={closings[i]}
                    onOpenInput={() => onOpenInput(i)}
                />
                <div
                className="surgeButton"
                onClick={() => toggleSymbolSurge(key)}
            >
                <svg viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
                    <path d="M77.6835 120.794C73.545 110.91 80.8045 100 91.5195 100H249.015C261.106 100 272.016 107.259 276.687 118.413L427.438 478.429C430.542 485.842 430.542 494.19 427.438 501.603L276.687 861.619C272.016 872.772 261.106 880.032 249.015 880.032H91.5195C80.8045 880.032 73.545 869.122 77.6835 859.238L227.438 501.603C230.542 494.19 230.542 485.842 227.438 478.429L77.6835 120.794Z"/>
                    <path d="M327.684 120.794C323.545 110.91 330.805 100 341.52 100H499.015C511.106 100 522.016 107.259 526.687 118.413L677.438 478.429C680.542 485.842 680.542 494.19 677.438 501.603L526.687 861.619C522.016 872.772 511.106 880.032 499.015 880.032H341.52C330.805 880.032 323.545 869.122 327.684 859.238L477.438 501.603C480.542 494.19 480.542 485.842 477.438 478.429L327.684 120.794Z"/>
                    <path d="M577.684 120.794C573.545 110.91 580.805 100 591.52 100H749.015C761.106 100 772.016 107.259 776.687 118.413L927.438 478.429C930.542 485.842 930.542 494.19 927.438 501.603L776.687 861.619C772.016 872.772 761.106 880.032 749.015 880.032H591.52C580.805 880.032 573.545 869.122 577.684 859.238L727.438 501.603C730.542 494.19 730.542 485.842 727.438 478.429L577.684 120.794Z"/>
                </svg>
            </div>
            </div>
        )
    }

    let probabilities = getProbabilities(
        tokens.map(t => ({...t, value: values[t.key]})),
        variableTokenValues,
        autoFailSymbols,
        surgeSymbols,
        blessCurse ? bless : 0,
        blessCurse ? curse : 0
    );


    let combinedProbabilities = [];

    probabilities.forEach((p, i) => {
        if (i > 0) {
            const prev = combinedProbabilities.at(-1);
            if (prev.winChance === p.winChance) {
                prev.label = `${prev.label}/+${p.balance}`
                prev.highestBalance = p.balance;
            } else {
                combinedProbabilities.push(
                    {
                        label: `${p.balance > 0 ? '+' : ''}${p.balance}`,
                        lowestBalance: p.balance,
                        winChance: p.winChance
                    }
                );
            }
        } else {
            combinedProbabilities.push({
                lowestBalance: p.balance,
                label: p.balance, winChance: p.winChance
            });
        }
    });

    const getPrefix = num => num > 0 ? '+' : '';

    combinedProbabilities = combinedProbabilities.map((p, i, a) => {
        const low = p.lowestBalance;
        let label = `${getPrefix(low)}${low}`;
        if (p.highestBalance !== undefined  && i < a.length - 1) {
            const high = p.highestBalance;
            let divider = high - low > 2 ? '...' : '/'
            label = `${label}${divider}${getPrefix(high)}${high}`;
        }
        return ({...p, label})
    })

    let hidden = false
    combinedProbabilities = combinedProbabilities.filter(p => {
        if (p.winChance < minimumInterestingChance) {
            hidden = true;
            return false;
        }
        return true;
    });

    const renderBalanceOption = (bo, i, a) => {
        let improvement = '';
        if (i > 0) {
            improvement = ` (+ ${bo.winChance - a[i - 1].winChance}%)`;
        }
        return (
            <Fragment key={bo.balance}>
                <div className="chanceLabel">
                    {bo.label}{bo.balance}:
                </div>
                <div className="chanceValue">
                    {bo.winChance}%
                    {improvement && <span className="improvement">{improvement}</span>}
                </div>
            </Fragment>
        )
    }

    const renderBlessCurse = () =>
        blessCurse ? (
            <div className="blessCurse">
                <div className="bless">
                    <span><Icon type="bless"/></span>
                    <NumberSelect
                        size={3}
                        min={0}
                        max={100}
                        numberBgColor="#000000"
                        selectorColor="#222222"
                        value={bless}
                        setValue={setBless}
                        timeout={3000}
                        closed={closings[4]}
                        onOpenInput={() => onOpenInput(4)}
                    />
                </div>
                <div className="curse">
                    <span><Icon type="curse"/></span>
                    <NumberSelect
                        size={3}
                        min={0}
                        max={100}
                        numberBgColor="#000000"
                        selectorColor="#222222"
                        value={curse}
                        setValue={v => setCurse(v)}
                        timeout={3000}
                        closed={closings[5]}
                        onOpenInput={() => onOpenInput(5)}
                    />
                </div>
            </div>
        ) : null;

    return (
        <div className="stats page">
            <div className="probabilities">
                <div>
                    {combinedProbabilities.map(renderBalanceOption).reverse()}
                </div>
                {hidden && (<p className="ellipses">...</p>)}
            </div>
            <div className="inputs">
            {renderBlessCurse()}
            <div className={`variableTokens ${(blessCurse && hideVariableTokens) ? 'hidden' : ''}`}>
                <div>
                    {renderVariableTokens()}
                </div>
            </div>
            {blessCurse && variableTokens.length > 0 && (<div className="hideVariableTokensButtonWrapper">
                    <button
                        onClick={() => setHideVariableTokens(!hideVariableTokens)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 -960 960 960"
                        >
                            <path
                                fill="white"
                                d={
                                    hideVariableTokens ?
                                        "m283-345-43-43 240-240 240 239-43 43-197-197-197 198Z" :
                                        "M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z"
                                }
                            />
                            :
                            {hideVariableTokens ?
                                "m283-345-43-43 240-240 240 239-43 43-197-197-197 198Z" :
                                "M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z"}
                        </svg>

                    </button>
                </div>
            )}

        </div>
        </div>
    );
}
