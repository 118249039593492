import React, {useEffect, useState} from 'react';
import {NumberSelect, ToggleSwitch} from "grevlingui";
import {Icon} from "../Icons/Icon";

export const Settings = ({
                             tokens, setTokens,
                             blessCurse, setBlessCurse,
                             minimumInterestingChance,
                             setMinimumInterestingChance
}) => {
    const [closings, setClosings] = useState([])

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    useEffect(() => {
        if (tokens && closings && closings.length !== tokens.length)
            setClosings(() => Array(Number(tokens.length)).fill(new Date()));
    }, [tokens, closings])

    const onOpenInput = (index) =>
        setClosings(c => c.map((prev, i) =>
            i === index ? prev : new Date()
        ))

    const renderTokenInput = (token, i) => (
        <div
            key={token.key}
            className="tokenInput"
        >
            <span className="label">
                {isNumeric(token.key) ? token.key : (<Icon type={token.key}/>)}
            </span>
            <NumberSelect
                size={3}
                min={0}
                max={10}
                numberBgColor="#000000"
                selectorColor="#222222"
                value={token.count}
                setValue={count => setTokens(pv =>  pv.with(i, {...pv[i], count}))}
                timeout={3000}
                onOpenInput={() => onOpenInput(i)}
                closed={closings[i]}
            />
        </div>
    )

    const numerical = tokens.filter(t => isNumeric(t.key));
    const symbols = tokens.filter(t => !isNumeric(t.key));

    return (
        <div className={'chaosChanceSettings'}>
            <div className="tokenInputs">
                <div className="tokenInputColumn">
                    {numerical
                     .sort((a, b) => b.key - a.key)
                        .map(renderTokenInput)
                    }
                </div>
                <div className="tokenInputColumn">
                    {symbols
                        .map((token, i) => renderTokenInput(token, i + 8))
                    }
                    <div className="tokenInputSpacer"/>
                </div>
            </div>
            <div className="options">
                <p className="minimumInterestingChance_header">
                    Minimum Interesting Chance
                </p>
                <div>
                <input
                    type="range"
                    min="0"
                    max="90"
                    step="10"
                    value={minimumInterestingChance}
                    onChange={e => setMinimumInterestingChance(e.target.value)}
                /> {
                    minimumInterestingChance
                }%
                </div>

                <ToggleSwitch
                    active={blessCurse}
                    onChange={setBlessCurse}
                    label={'Bless/curse'}
                />
            </div>
        </div>
    );
}