import React from 'react';
import {ReactComponent as Eldersign} from "./Eldersign.svg";
import {ReactComponent as Cultist} from "./Cultist.svg";
import {ReactComponent as Fishface} from "./Fishface.svg";
import {ReactComponent as Skull} from "./Skull.svg";
import {ReactComponent as Tablet} from "./Tablet.svg";
import {ReactComponent as Tentacle} from "./Tentacle.svg";
import {ReactComponent as Bless} from "./BlessSimple.svg";
import {ReactComponent as Curse} from "./CurseSimple.svg";

export const Icon = ({type}) => {
    switch (type) {
        case 'elder':return <Eldersign/>;
        case 'fish':return <Fishface/>;
        case 'skull':return <Skull/>;
        case 'tablet':return <Tablet/>;
        case 'cultist':return <Cultist/>;
        case 'bless':return <Bless/>;
        case 'curse':return <Curse/>;
        default:
            return <Tentacle/>;
    }
}