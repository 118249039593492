
const modScenario = (s, probMulti, m, d) => {
    const modifier = parseInt(s.modifier) + parseInt(m);
    const probability = s.probability * probMulti;
    const draws = [...s.draws, d];
    return {...s, modifier, probability, draws};
}

const getPossibilities = (
    currentState,
    surgeMap,
    normalTokens
) => {
    const scenarios = []
    const totalNumberOfTokens = normalTokens + Object.values(surgeMap).reduce((a, b) => a + b, 0);

    scenarios.push(modScenario(currentState, normalTokens / totalNumberOfTokens, 0, 'normal'))

    Object.entries(surgeMap).forEach(([value, count]) => {
        if (count === 0) return;
        const probabilityOfThisToken = count / totalNumberOfTokens;
        const newState = modScenario(currentState, probabilityOfThisToken, value, `surge, ${value}`)
        const newSurgeMap = {...surgeMap}
        newSurgeMap[value] = count -1 ;
        scenarios.push(
            ...getPossibilities(
                newState,
                newSurgeMap,
                normalTokens
            )
        );
    });

    return scenarios;
}

const getScenarios = (surgeMap, numberOfNormalTokens) =>
    getPossibilities(
        {
            probability: 1,
            draws: [],
            modifier: 0
        },
        surgeMap,
        numberOfNormalTokens
    );

const getModificationProbabilities = (scenarios) =>
    scenarios.reduce((acc, s) => ({
        ...acc,
        [s.modifier]: (acc[s.modifier] ?? 0) + s.probability
    }), {});

export const getProbabilities = (
    tokens,
    variableTokenValues,
    autoFailSymbols,
    surgeSymbols,
    bless,
    curse
) => {

    const adjusted = tokens.map(t => (
        {
            ...t,
            value:
                autoFailSymbols.includes(t.key) ? false :
                    variableTokenValues[t.key] ?? t.value,
            surge: surgeSymbols.includes(t.key)
        }
    ))

    let totalCount =  adjusted.filter(t => t.value === false).length;

    const valuesMap = {};
    adjusted.forEach(t => {
        if (t.surge || t.value === false) return;
        totalCount = totalCount + t.count;
        valuesMap[t.value] = (valuesMap[t.value] ?? 0) + t.count;
    })


    const values = adjusted
        .filter(t => t.value !== false)
        .map(t => t.value);


    const thingyEntries = Object.entries(valuesMap)
        .sort((ar1, ar2) => ar2[0] - ar1[0]);

    const surgeMap = {};


    adjusted.filter(t => t.surge && t.value)
        .forEach(t => {
            surgeMap[t.value] = (surgeMap[t.value] ?? 0) + t.count;
        })

    surgeMap[2] = (surgeMap[2] ?? 0) + bless;
    surgeMap[-2] = (surgeMap[-2] ?? 0) + curse;

    const numberOfNormalTokens = thingyEntries.reduce((acc, [k, v]) => {
        if (surgeSymbols.includes(k)) return acc;
        return acc + v;
    }, 0);

    const scenarios = getScenarios(surgeMap, numberOfNormalTokens);
    const modProbs = getModificationProbabilities(scenarios);

    const highestModifier = Math.max(...scenarios.map(s => s.modifier));
    const lowestModifier = Math.min(...scenarios.map(s => s.modifier));

    const lowest = Math.min(...values) + lowestModifier;
    const highest = Math.max(...values) + highestModifier;

    const highestUseful = Math.abs(lowest);

    const balanceOptions = [];

    for (let balance = 0 - highest; balance < highestUseful + 1; balance++) {
        let successChance = 0;

        Object.entries(modProbs).forEach(([modifier, probability]) => {
            const effectiveBalance = balance + parseInt(modifier);
            let winnable = thingyEntries.filter(e => parseInt(e[0]) + effectiveBalance >= 0);
            let winnableCount = winnable.reduce((acc, e) => acc + e[1], 0);
            successChance += (winnableCount / totalCount) * probability;
        })
        const thingy = {balance, winChance: Math.round(successChance * 100)};
        balanceOptions.push(thingy)
    }

    return balanceOptions
}

/*
    Case 1
    -1, 0, 1
    1 x skull -1

        normal:
        -1: 1/4
        0: 1/2
        1: 1

        skull surge:g
        -1:  1/6
        0: 1/2
        1: 5/6
        2: 1

        cases:
            1, 1/4
            0, 1/4
            skull: 1 (0) 1/4*1/3
            -1, 1/4
            skull: 0 (-1) 1/4*1/3
            skull: -1 (-2) 1/4*1/3
          outcomes:
          1: 1/4 = 0.25
          0: (1/4) + (1/4*1/3) = 0.33333333
          -1: (1/4) + (1/4*1/3) = 0.33333333
          -2: (1/4*1/3) = 0.08333

        Ser ut som det stemmer!


 */
